<script setup lang="ts">
import moment from 'moment'
import { useTheme } from 'vuetify'
import GoogleSheet from '@/views/front-pages/performance/GoogleSheet.vue'
import Top100 from '@/views/front-pages/performance/Top100.vue'

enum LoadingState {
  Initial,
  Loading,
  Loaded,
}
const exchanges = ref([])
const pairs = ref([])
const exchange = ref()
const pair = ref()
const exchangeState = ref(LoadingState.Initial)
const pairState = ref(LoadingState.Initial)
const pageState = ref(LoadingState.Initial)

const state = reactive({
  start_date: '2020-01-01',
  end_date: '',
  exchange: '',
  pair: '',
  initial_capital: '',
})

const getExchanges = async () => {
  exchangeState.value = LoadingState.Loading
  exchanges.value = await $api('/performance/exchanges')
  exchangeState.value = LoadingState.Loaded
}

const getPairs = async () => {
  pairState.value = LoadingState.Loading
  pairs.value = await $api(`/performance/pairs?exchange=${exchange.value.id}`)
  pairState.value = LoadingState.Loaded
}

getExchanges()

definePage({
  meta: {
    layout: 'front',
  },
})

const onExchangeChanged = async () => {
  await getPairs()
}

const result = ref({
  tradeasy: {
    net_result: 0,
    total_deals: 0,
    final_capital: 0,
    daily_avg: 0,
  },
  bah: {
    initial_price: 0,
    last_price: 0,
    net_result: 0,
    daily_avg: 0,
    final_capital: 0,
  },
  cap: [],
  ratio: 0,
})

const initialCapital = computed({
  get() {
    return state.initial_capital
  },
  set(newVal) {
    state.initial_capital = Number.parseFloat(newVal)
  },
})

const numberOnly = (evt: KeyboardEvent) => {
  const keysAllowed: string[] = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.']
  const keyPressed: string = evt.key

  if (!keysAllowed.includes(keyPressed))
    evt.preventDefault()
  evt.target.value = Number.parseFloat(evt.target.value)
}

const showResults = async () => {
  pageState.value = LoadingState.Loading

  const params = new URLSearchParams(state)

  result.value = await $api(`/performance/results?${params}`)
  pageState.value = LoadingState.Loaded
}

const limits = reactive({
  start_date: '2020-01-01',
  end_date: new Date(),
})

const endDateMin = computed(() => moment(state.start_date).add(1, 'days').toDate())

const onPairChanged = async () => {
  const limit = await $api(`/performance/limits?pair=${state.pair}`)
  if (state.start_date < limit.min)
    state.start_date = limit.min

  if (state.end_date > limit.max || !state.end_date)
    state.end_date = limit.max

  limits.start_date = limit.min
  limits.end_date = limit.max
}

const { current } = useTheme()

const isDark = computed(() => current.value.dark)

watch(exchange, newVal => {
  state.exchange = newVal.id
  onExchangeChanged()
})

watch(pair, newVal => {
  state.pair = newVal.id

  showResults()

  // onPairChanged()
})

const symbol = computed(() => `${exchange.value.name}:${pair.value.name}`)

const tab = ref()
const top = ref()
const tradeasy = ref()
</script>

<template>
  <VContainer>
    <VRow>
      <VCol>
        <VTabs v-model="tab">
          <VTab value="tradeasy">
            Tradeasy Back testing summary
          </VTab>
          <VTab value="performance">
            Tradeasy Top pairs
          </VTab>
          <VTab value="top">
            MarketCap Top Pairs
          </VTab>
        </VTabs>
      </VCol>
    </VRow>
    <VWindow v-model="tab">
      <VWindowItem value="tradeasy">
        <VTabs v-model="tradeasy">
          <VTab value="all">
            All
          </VTab>
          <VTab value="binance">
            Binance
          </VTab>
          <VTab value="htx">
            HTX
          </VTab>
          <VTab value="kucoin">
            Kucoin
          </VTab>
          <VTab value="kraken">
            Kraken
          </VTab>
        </VTabs>
        <VWindow v-model="tradeasy">
          <VWindowItem value="all">
            <GoogleSheet
              :height="900"
              width="988px"
              :src="isDark ? 'https://docs.google.com/spreadsheets/d/e/2PACX-1vT5KRztaF34akleKHYflzgk63d966i7WvnFR7CCfBrovrIGkK5veFJ5Eq-ec8qGnYU4nzODrX2dVK9B/pubhtml?gid=572438245&amp;single=true&amp;widget=true&amp;headers=false' : 'https://docs.google.com/spreadsheets/d/e/2PACX-1vT5KRztaF34akleKHYflzgk63d966i7WvnFR7CCfBrovrIGkK5veFJ5Eq-ec8qGnYU4nzODrX2dVK9B/pubhtml?gid=23498178&amp;single=true&amp;widget=true&amp;headers=false&amp;rm=minimal'"
            />
          </VWindowItem>
          <VWindowItem value="binance">
            <GoogleSheet
              :height="900"
              width="988px"
              :src="isDark ? 'https://docs.google.com/spreadsheets/d/e/2PACX-1vT5KRztaF34akleKHYflzgk63d966i7WvnFR7CCfBrovrIGkK5veFJ5Eq-ec8qGnYU4nzODrX2dVK9B/pubhtml?gid=877663663&amp;single=true&amp;widget=true&amp;headers=false' : 'https://docs.google.com/spreadsheets/d/e/2PACX-1vT5KRztaF34akleKHYflzgk63d966i7WvnFR7CCfBrovrIGkK5veFJ5Eq-ec8qGnYU4nzODrX2dVK9B/pubhtml?gid=623294410&amp;single=true&amp;widget=true&amp;headers=false&amp;rm=minimal'"
            />
          </VWindowItem>
          <VWindowItem value="htx">
            <GoogleSheet
              :height="900"
              width="988px"
              :src="isDark ? 'https://docs.google.com/spreadsheets/d/e/2PACX-1vT5KRztaF34akleKHYflzgk63d966i7WvnFR7CCfBrovrIGkK5veFJ5Eq-ec8qGnYU4nzODrX2dVK9B/pubhtml?gid=732152967&amp;single=true&amp;widget=true&amp;headers=false' : 'https://docs.google.com/spreadsheets/d/e/2PACX-1vT5KRztaF34akleKHYflzgk63d966i7WvnFR7CCfBrovrIGkK5veFJ5Eq-ec8qGnYU4nzODrX2dVK9B/pubhtml?gid=572712680&amp;single=true&amp;widget=true&amp;headers=false&amp;rm=minimal'"
            />
          </VWindowItem>
          <VWindowItem value="kucoin">
            <GoogleSheet
              :height="900"
              width="988px"
              :src="isDark ? 'https://docs.google.com/spreadsheets/d/e/2PACX-1vT5KRztaF34akleKHYflzgk63d966i7WvnFR7CCfBrovrIGkK5veFJ5Eq-ec8qGnYU4nzODrX2dVK9B/pubhtml?gid=1873290435&amp;single=true&amp;widget=true&amp;headers=false' : 'https://docs.google.com/spreadsheets/d/e/2PACX-1vT5KRztaF34akleKHYflzgk63d966i7WvnFR7CCfBrovrIGkK5veFJ5Eq-ec8qGnYU4nzODrX2dVK9B/pubhtml?gid=1978846232&amp;single=true&amp;widget=true&amp;headers=false&amp;rm=minimal'"
            />
          </VWindowItem>
          <VWindowItem value="kraken">
            <GoogleSheet
              :height="900"
              width="988px"
              :src="isDark ? 'https://docs.google.com/spreadsheets/d/e/2PACX-1vT5KRztaF34akleKHYflzgk63d966i7WvnFR7CCfBrovrIGkK5veFJ5Eq-ec8qGnYU4nzODrX2dVK9B/pubhtml?gid=1321857535&amp;single=true&amp;widget=true&amp;headers=false' : 'https://docs.google.com/spreadsheets/d/e/2PACX-1vT5KRztaF34akleKHYflzgk63d966i7WvnFR7CCfBrovrIGkK5veFJ5Eq-ec8qGnYU4nzODrX2dVK9B/pubhtml?gid=2088690603&amp;single=true&amp;widget=true&amp;headers=false&amp;rm=minimal'"
            />
          </VWindowItem>
        </VWindow>
      </VWindowItem>
      <VWindowItem value="performance">
        <VTabs v-model="top">
          <VTab value="binance">
            Tradeasy TOP 100 Pairs Binance
          </VTab>
          <VTab value="htx">
            Tradeasy TOP 100 Pairs HTX
          </VTab>
          <VTab value="kucoin">
            Tradeasy TOP 100 Pairs Kucoin
          </VTab>
          <VTab value="kraken">
            Tradeasy TOP 100 Pairs Kraken
          </VTab>
        </VTabs>
        <VWindow v-model="top">
          <VWindowItem value="binance">
            <GoogleSheet :height="2777" :src="isDark ? 'https://docs.google.com/spreadsheets/d/e/2PACX-1vT5KRztaF34akleKHYflzgk63d966i7WvnFR7CCfBrovrIGkK5veFJ5Eq-ec8qGnYU4nzODrX2dVK9B/pubhtml?gid=1457742873&amp;single=true&amp;headers=false&amp;rm=minimal&amp;range=A1:P152' : 'https://docs.google.com/spreadsheets/d/e/2PACX-1vT5KRztaF34akleKHYflzgk63d966i7WvnFR7CCfBrovrIGkK5veFJ5Eq-ec8qGnYU4nzODrX2dVK9B/pubhtml?gid=1248095075&amp;single=true&amp;headers=false&amp;rm=minimal&amp;range=A1:P152'" />
          </VWindowItem>
          <VWindowItem value="htx">
            <GoogleSheet :height="2725" :src="isDark ? 'https://docs.google.com/spreadsheets/d/e/2PACX-1vT5KRztaF34akleKHYflzgk63d966i7WvnFR7CCfBrovrIGkK5veFJ5Eq-ec8qGnYU4nzODrX2dVK9B/pubhtml?gid=146312725&amp;single=true&amp;headers=false&amp;rm=minimal&amp;range=A1:P152' : 'https://docs.google.com/spreadsheets/d/e/2PACX-1vT5KRztaF34akleKHYflzgk63d966i7WvnFR7CCfBrovrIGkK5veFJ5Eq-ec8qGnYU4nzODrX2dVK9B/pubhtml?gid=618387271&amp;single=true&amp;headers=false&amp;rm=minimal&amp;range=A1:P152'" />
          </VWindowItem>
          <VWindowItem value="kucoin">
            <GoogleSheet :height="2725" :src="isDark ? 'https://docs.google.com/spreadsheets/d/e/2PACX-1vT5KRztaF34akleKHYflzgk63d966i7WvnFR7CCfBrovrIGkK5veFJ5Eq-ec8qGnYU4nzODrX2dVK9B/pubhtml?gid=1915052376&amp;single=true&amp;headers=false&amp;rm=minimal&amp;range=A1:P152' : 'https://docs.google.com/spreadsheets/d/e/2PACX-1vT5KRztaF34akleKHYflzgk63d966i7WvnFR7CCfBrovrIGkK5veFJ5Eq-ec8qGnYU4nzODrX2dVK9B/pubhtml?gid=804531661&amp;single=true&amp;widget=true&amp;headers=false&amp;rm=minimal&amp;range=A1:P152'" />
          </VWindowItem>
          <VWindowItem value="kraken">
            <GoogleSheet :height="2803" :src="isDark ? 'https://docs.google.com/spreadsheets/d/e/2PACX-1vT5KRztaF34akleKHYflzgk63d966i7WvnFR7CCfBrovrIGkK5veFJ5Eq-ec8qGnYU4nzODrX2dVK9B/pubhtml?gid=1013000077&amp;single=true&amp;headers=false&amp;rm=minimal&amp;range=A1:P152' : 'https://docs.google.com/spreadsheets/d/e/2PACX-1vT5KRztaF34akleKHYflzgk63d966i7WvnFR7CCfBrovrIGkK5veFJ5Eq-ec8qGnYU4nzODrX2dVK9B/pubhtml?gid=528315568&amp;single=true&amp;widget=true&amp;headers=false&amp;range=A1:P152'" />
          </VWindowItem>
        </VWindow>
        <p>* The profit Tradeasy and Buy and hold are calculated from the 1st january 2020 until the first of this month</p>
        <div class="d-flex justify-center align-items-center mt-3 mb-10">
          <VBtn class="text-none mt-10 text-center" size="x-large" href="https://www.tradingview.com/script/dMYH65Y5-TRADEASY/" target="_blank">
            To check the Tradeasy TradingView<br> please click here
          </VBtn>

        </div>
        <img class="w-100" :src="isDark ? '/images/tradeasy-b.jpeg' : '/images/tradeasy.jpeg'" alt="">
      </VWindowItem>
      <VWindowItem value="top">
        <Top100 />
      </VWindowItem>
    </VWindow>
  </VContainer>
</template>

<style scoped lang="scss">
.sheet-frame {
  min-height: 500px;
}
.bg-white {
  background: #fff;
}

.text-black {
  color: black;
}
.page-overlay {
  --v-overlay-scrim-opacity: 1!important;
}
.transparent-overlay{
  --v-overlay-scrim-opacity: 0!important;
}
</style>
